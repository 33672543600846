import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import styled, { keyframes } from 'styled-components'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content from '../components/Content'
import TopBanner from '../components/TopBanner'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const StyledEventInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: calc(40vw - 35px);
  height: 100%;
  background: transparent;
  padding: 0 10px;
  width: 100%;
  border-radius: 0 3px 3px 0;
  @media (max-width: 768px) {
    flex: 1;
    border-radius: 0 0 3px 3px;
  }
`

const flicker = keyframes`
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70% {
    text-shadow: 15px -15px beige, -15px -15px #5991e626, 0 -10px beige;
    opacity: 0.9;
  }
  20%, 21.999%, 63% {
    text-shadow: 10px -10px beige, -10px -10px #5991e626, 0 -10px beige;
    opacity: 0.4;
  }
  63.999%, 65%, 69.999% {
    text-shadow: 5px -5px beige, -5px -5px #5991e626, 0 -10px beige;
    opacity: 0.9;
  }
  100% {
    color: #eceae5;
    text-shadow: none;
  }
`

const EventTitle = styled.h2`
  display: bloc;
  padding: 20px;
  padding-bottom: 0;
  border-bottom: none;
  letter-spacing: 1vw;
  font-size: calc(20px + 2vw);
  text-align: left;
  text-shadow: 1px 0px 3px black;
  text-decoration: line-through;
  background: rgb(34, 34, 34);
  color: rgb(34, 34, 34);
  letter-spacing: 1.5vw;
  text-shadow: 1px 0px 3px black;
  @media (max-width: 768px) {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const EventTitle2 = styled.h2`
  display: flex;
  min-height: 8vh;
  font-size: calc(18px + 2vw);
  padding-top: none;
  align-items: flex-end;
  margin-left: -9px;
  letter-spacing: 1vw;
  background: rgb(34, 34, 34);
  color: rgb(34, 34, 34);
  writing-mode: vertical-rl;
  width: 5vw;
  position: relative;
  border-radius: 3px 0 0 3px;
  text-shadow: 1px 0px 3px black;
  text-decoration: line-through;
`

const EventTitle3 = styled.h2`
  display: flex;
  color: #ffcccc;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(18px + 1vw);
  line-height: calc(24px + 1vw);
  letter-spacing: 0.5vw;
`

const NarrowInfo = styled.div`
  width: 100%;
  color: transparent;
  justify-content: center;
  align-items: center;
  padding: 1vh 0;
  display: flex;
  text-transform: uppercase;
  font-size: calc(12px + 1vw);
  text-shadow: 15px -15px beige, -15px -15px #5991e626, 0 -10px #affdd3;
  animation: ${flicker} .25s;
  animation-fill-mode: forwards;
  font-size: calc(8px + 1vw);
}
`

const InfoDetails = styled.section`
  width: 100%;
  color: transparent;
  justify-content: flex-start;
  align-items: center;
  padding: 1vh 0;
  display: flex;
  text-transform: uppercase;
  font-size: calc(12px + 1vw);
  text-shadow: 15px -15px beige, -15px -15px #5991e626, 0 -10px #affdd3;
  animation: ${flicker} .25s;
  animation-fill-mode: forwards;
  font-size: calc(8px + 1vw);
`

const EventDescription = styled.section`
  display: flex;
  width: 100%;
  line-height: calc(16px + 2vw);
  font-size: calc(12px + 0.75vw);
  color: #eceae5;
  padding: 10px 20px 10px 20px;
`

const StyledImageContainer = styled.div`
  height: 100%;
  max-height: 75vh;
  min-width: calc(55vw - 35px);
  width: 100%;
  display: flex;
  flex-grow: 1;
  border: 1px solid #ffcccc;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.23);;
  @media (max-width: 768px) {
    flex: 1;
    max-height: 70%;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  margin: 20px;
  margin-right: 0;
  border: none;
  border-radius: 0 3px 3px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    border-radius: 0 0 3px 3px;
  }
`
const StyledContainer = styled.section`
  display: flex;
  padding: 0 0 0 25px;
  background-color: rgb(34, 34, 34);
`

const StyledAnchor = styled.a.attrs(props => ({
  href: props.href,
}))`
  text-decoration: none;
  color: #e6c992;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  display: flex;
  width: 100%;
  line-height: calc(16px + 2vw);
  font-size: calc(12px + 0.75vw);
  letter-spacing: 2px;
  color: #eceae5;
  border: 1px solid #e6c992;
  border-radius: 2px;
  padding: 8px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.23);
  background: rgb(34, 34, 34);
  color: #eceae5;
  transition: color 1s ease;
  transition: background 1s ease;
  :visited {
    color: #e6c992;
  }
  :hover{
    background: #e6c992;
    color: rgb(34, 34, 34);
    transition: color 1s ease;
    transition: background 1s ease;
  }
 `

const Description = styled.h2`
  display: flex;
  min-height: 8vh;
  font-size: calc(18px + 2vw);
  padding-top: 10px;
  align-items: flex-end;
  letter-spacing: 1vw;
  color: #e6c992;
  writing-mode: vertical-rl;
  width: 5vw;
  position: relative;
`
const DescriptionText = styled.p`
  margin-left: 25px;
`
const InfoTitle = styled.h4`
  color: #affdd3;
  margin-right: 5px;
  min-width: 100px;
`

const InfoDetail = styled.span`
  margin-left: 20px;
`

export const EventPostTemplate = props => {
  const {
    poster,
    description,
    title,
    ticket,
    helmet,
    event: { hours, date },
    venue,
    location,
  } = props
  console.log(hours)
  return (
    <section className="section">
      {helmet || ''}
      <Layout hasPadding style={{ minHeight: '100%' }}>
        <TopBanner noBackButton />
        <EventTitle>
          {title}
        </EventTitle>
        <StyledContainer>
          <EventTitle2>
            {title.slice(1)}
          </EventTitle2>
          <StyledContent>
            <StyledImageContainer>
              <PreviewCompatibleImage
                imageInfo={{
                  alt: 'poster',
                  imgStyle: { objectFit: 'contain' },
                  style: { width: '100%', height: '100%' },
                  image: poster,
                }}
              />
            </StyledImageContainer>
            <StyledEventInfoContainer>
              <NarrowInfo style={{ paddingTop: 0 }}>
                <EventTitle3>
                  {title}
                </EventTitle3>
              </NarrowInfo>
              <InfoDetails>
                <InfoTitle>Date:</InfoTitle>
                <InfoDetail>{date}</InfoDetail>
              </InfoDetails>
              {hours &&
                <InfoDetails>
                  <InfoTitle>Hours:</InfoTitle>
                  <InfoDetail>{hours}</InfoDetail>
                </InfoDetails>
              }
              <InfoDetails>
                <InfoTitle>Venue:</InfoTitle>
                <InfoDetail>{venue}</InfoDetail>
              </InfoDetails>
              <InfoDetails>
                <InfoTitle>Location:</InfoTitle>
                <InfoDetail>{location}</InfoDetail>
              </InfoDetails>
              {ticket &&
                <em><StyledAnchor href={ticket}>TICKETS</StyledAnchor></em>}
            </StyledEventInfoContainer>
          </StyledContent>
        </StyledContainer>
        {description && description.length > 0 &&
          <EventDescription>
            <Description>DETAILS</Description>
            <DescriptionText>{description}</DescriptionText>
          </EventDescription>
        }
      </Layout>
    </section>
  )
}

EventPostTemplate.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  poster: PropTypes.object,
  event: PropTypes.object,
  id: PropTypes.string,
}

const EventPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <EventPostTemplate
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Event">
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        id={data.id}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        poster={post.frontmatter.poster}
        event={post.frontmatter.event}
        ticket={post.frontmatter.ticket}
        venue={post.frontmatter.venue}
        location={post.frontmatter.location}
        hours={post.frontmatter.hours}
      />
    </Layout>
  )
}

EventPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default EventPost

export const pageQuery = graphql`
  query EventPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        event {
          date
          hours
        }
        tags
        ticket
        venue
        location
        poster {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 96) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
